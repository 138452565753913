import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/DocsLayout.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Alert = makeShortcode("Alert");
const Code = makeShortcode("Code");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Getting Help`}</h2>
    <p><inlineCode parentName="p">{`step`}</inlineCode>{` ships with extensive built-in help. To list available options and command groups, run `}<inlineCode parentName="p">{`step`}</inlineCode>{` by itself. For help, use `}<inlineCode parentName="p">{`step help <command>`}</inlineCode>{` or `}<inlineCode parentName="p">{`step help <command> <subcommand>`}</inlineCode>{`.`}</p>
    <h2>{`Environment variables`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`STEPPATH`}</inlineCode>{` The path where `}<inlineCode parentName="li">{`step`}</inlineCode>{` stores configuration and state. This directory also holds `}<inlineCode parentName="li">{`step-ca`}</inlineCode>{` state created with `}<a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/ca/init"
        }}><inlineCode parentName="a">{`step ca init`}</inlineCode></a>{`, including CA configuration, keys, certificates, and templates. Defaults to `}<inlineCode parentName="li">{`$HOME/.step`}</inlineCode>{`.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`STEPDEBUG`}</inlineCode>{` When set to `}<inlineCode parentName="li">{`1`}</inlineCode>{`, `}<inlineCode parentName="li">{`step`}</inlineCode>{` will provide extra diagnostic information for debugging. This variable can also be used with `}<inlineCode parentName="li">{`step-ca`}</inlineCode>{`.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`HTTPS_PROXY`}</inlineCode>{` and `}<inlineCode parentName="li">{`NO_PROXY`}</inlineCode>{` Configure proxies for outbound HTTPS traffic. See `}<a parentName="li" {...{
          "href": "https://golang.org/pkg/net/http/#ProxyFromEnvironment"
        }}>{`net/http.ProxyFromEnvironment`}</a>{` documentation for details. Note that the system trust store is `}<em parentName="li">{`not`}</em>{` trusted by `}<inlineCode parentName="li">{`step`}</inlineCode>{` for the TLS handshake with the proxy server.`}
        <ul parentName="li">
          <li parentName="ul">{`The proxy server will need to be configured to trust the CA.`}</li>
          <li parentName="ul">{`Only `}<inlineCode parentName="li">{`HTTPS_PROXY`}</inlineCode>{` is needed; `}<inlineCode parentName="li">{`step`}</inlineCode>{`'s outbound connections are all HTTPS.`}</li>
          <li parentName="ul">{`Add a `}<inlineCode parentName="li">{`--root`}</inlineCode>{` or `}<inlineCode parentName="li">{`STEP_ROOT`}</inlineCode>{` These files contain both the step CA certificate, and the proxy CA certificate will be trusted by step.`}</li>
        </ul>
      </li>
    </ul>
    <h3>{`Passing flags as environment variables`}</h3>
    <p>{`You can pass flags to `}<inlineCode parentName="p">{`step`}</inlineCode>{` using environment variables, using `}<inlineCode parentName="p">{`STEP_<flag>`}</inlineCode>{`. For example, `}<inlineCode parentName="p">{`STEP_RAW=true step ssh list`}</inlineCode>{` is equivalent to `}<inlineCode parentName="p">{`step ssh list --raw`}</inlineCode>{`. Command flags have precedence over environment variable flags, which in turn have precedence over the values in the `}<a parentName="p" {...{
        "href": "#configuration-file"
      }}>{`configuration file`}</a>{`.`}</p>
    <h2>{`Configuration file`}</h2>
    <p>{`The file `}<inlineCode parentName="p">{`$(step path)/config/defaults.json`}</inlineCode>{` can contain a JSON object listing default values for any flags accepted by `}<inlineCode parentName="p">{`step`}</inlineCode>{` commands. It is typically used to store CA connection information, eg:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "ca-url": "https://ca.internal:8443",
  "fingerprint": "93cff06dc36251fb0c4985d0b5ed7265a368cd70697fba90355c93cc4aabff0d",
  "root": "/Users/carl/.step/certs/root_ca.crt",
  "redirect-url": ""
}
`}</code></pre>
    <Alert severity="info" mdxType="Alert">
    It is not currently possible to segment default keys in <Code mdxType="Code">defaults.json</Code> by subcommand. All keys are applied to all <Code mdxType="Code">step</Code> commands.
    </Alert>
    <h2>{`Contexts: Working with multiple CAs`}</h2>
    <p>{`By default, the `}<inlineCode parentName="p">{`step`}</inlineCode>{` toolchain assumes a single CA is in use, and it will store client configuration, CA configuration, and state directories (`}<inlineCode parentName="p">{`config`}</inlineCode>{`, `}<inlineCode parentName="p">{`certs`}</inlineCode>{`, `}<inlineCode parentName="p">{`secrets`}</inlineCode>{`, `}<inlineCode parentName="p">{`db`}</inlineCode>{`, etc.) inside a single folder, `}<inlineCode parentName="p">{`$HOME/.step`}</inlineCode>{`.`}</p>
    <p>{`If you regularly work with multiple CAs, we recommend enabling `}<em parentName="p">{`contexts`}</em>{`.`}</p>
    <p>{`Contexts let you configure and select multiple CAs and configuration profiles.
If this optional feature is enabled, the `}<inlineCode parentName="p">{`$HOME/.step`}</inlineCode>{` folder will have a different layout.`}</p>
    <p>{`Every context consists of a client configuration profile (including `}<a parentName="p" {...{
        "href": "#configuration-file"
      }}><inlineCode parentName="a">{`defaults.json`}</inlineCode></a>{`),
and a directory tree for CA configuration and state data (`}<inlineCode parentName="p">{`ca.json`}</inlineCode>{`, CA roots, certificates, secrets, templates, db).`}</p>
    <p>{`To enable contexts,
pass the `}<inlineCode parentName="p">{`--context`}</inlineCode>{` flag to any of the `}<inlineCode parentName="p">{`step`}</inlineCode>{` setup commands, along with a label for your new context:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ step ca bootstrap \\
       --ca-url https://ca.internal:4443 \\
       --fingerprint c8d3...7aa \\
       --context vpn
The root certificate has been saved in /home/carl/.step/authorities/ca.internal/certs/root_ca.crt.
The authority configuration has been saved in /home/carl/.step/authorities/ca.internal/config/defaults.json.
The profile configuration has been saved in /home/carl/.step/profiles/ca.internal/config/defaults.json.
$ step context current
vpn
$ step path
/home/carl/.step/authorities/ca.internal
`}</code></pre>
    <p>{`Contexts are enabled, and a new context is created, if `}<inlineCode parentName="p">{`--context [name]`}</inlineCode>{` is passed to any of the following:`}</p>
    <ul>
      <li parentName="ul">{`CA client bootstrap: `}<a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/ca/bootstrap"
        }}><inlineCode parentName="a">{`step ca bootstrap`}</inlineCode></a></li>
      <li parentName="ul">{`CA server init: `}<a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/ca/init"
        }}><inlineCode parentName="a">{`step ca init`}</inlineCode></a></li>
      <li parentName="ul">{`CA server startup: `}<inlineCode parentName="li">{`step-ca`}</inlineCode></li>
      <li parentName="ul">{`SSH client bootstrap: `}<a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/ssh/config"
        }}><inlineCode parentName="a">{`step ssh config`}</inlineCode></a></li>
    </ul>
    <p>{`When contexts are enabled:`}</p>
    <ul>
      <li parentName="ul">{`Client configuration is stored in the `}<inlineCode parentName="li">{`profiles`}</inlineCode>{` directory, and CA server configuration and data is stored in `}<inlineCode parentName="li">{`authorities`}</inlineCode>{` directory.`}</li>
      <li parentName="ul">{`Context configuration files `}<inlineCode parentName="li">{`contexts.json`}</inlineCode>{` and `}<inlineCode parentName="li">{`current-context.json`}</inlineCode>{` are created in the top-level `}<inlineCode parentName="li">{`$STEPPATH`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`There is always a currently active context (`}<a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/context/current"
        }}><inlineCode parentName="a">{`step context current`}</inlineCode></a>{`), but you can pass a `}<inlineCode parentName="li">{`--context`}</inlineCode>{` name to any `}<a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/ca"
        }}><inlineCode parentName="a">{`step ca`}</inlineCode></a>{`, `}<a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/ssh/"
        }}><inlineCode parentName="a">{`step ssh`}</inlineCode></a>{`, or `}<inlineCode parentName="li">{`step-ca`}</inlineCode>{` command to temporarily select a context for a single operation.`}</li>
      <li parentName="ul">{`Use the `}<a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/context/"
        }}><inlineCode parentName="a">{`step context`}</inlineCode></a>{` command group to switch contexts, remove a context and its associated configuration, and view the current context.`}</li>
      <li parentName="ul">{`The `}<a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/path/"
        }}><inlineCode parentName="a">{`step path`}</inlineCode></a>{` command will show the current context's path. To display the top-level configuration directory name (`}<inlineCode parentName="li">{`$STEPPATH`}</inlineCode>{`) when contexts are enabled, use `}<inlineCode parentName="li">{`step path --base`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`Two `}<a parentName="li" {...{
          "href": "#configuration-file"
        }}><inlineCode parentName="a">{`defaults.json`}</inlineCode></a>{` files are created: One in the `}<inlineCode parentName="li">{`profiles`}</inlineCode>{` tree, and one in the `}<inlineCode parentName="li">{`authorities`}</inlineCode>{` tree. They are merged, and the one in `}<inlineCode parentName="li">{`profiles`}</inlineCode>{` takes precedence.`}</li>
      <li parentName="ul">{`Context configuration files `}<inlineCode parentName="li">{`contexts.json`}</inlineCode>{` and `}<inlineCode parentName="li">{`current-context.json`}</inlineCode>{` are created in the top-level `}<inlineCode parentName="li">{`$STEPPATH`}</inlineCode>{`.`}</li>
    </ul>
    <h3>{`Migrating to Contexts`}</h3>
    <p>{`There is no support for automatically migrating a single-profile `}<inlineCode parentName="p">{`$STEPPATH`}</inlineCode>{` configuration into its own context.
Enabling contexts will preserve any previously existing configuration or state data in `}<inlineCode parentName="p">{`$STEPPATH`}</inlineCode>{`,
but it will not migrate your existing configuration tree into its own new context.`}</p>
    <h2>{`Extending `}<inlineCode parentName="h2">{`step`}</inlineCode></h2>
    <p>{`The `}<inlineCode parentName="p">{`step`}</inlineCode>{` command supports plugins for adding subcommands.
A plugin is an executable file called `}<inlineCode parentName="p">{`step-COMMANDNAME-plugin`}</inlineCode>{` located in `}<inlineCode parentName="p">{`$PATH`}</inlineCode>{` or `}<inlineCode parentName="p">{`$STEPPATH/plugins`}</inlineCode>{`.
For example, a plugin named `}<inlineCode parentName="p">{`$STEPPATH/plugins/step-dns-plugin`}</inlineCode>{` will execute when `}<inlineCode parentName="p">{`step dns`}</inlineCode>{` is run.
If you're writing a plugin, please follow our convention for CLI help by implementing eg. `}<inlineCode parentName="p">{`step dns help`}</inlineCode>{`.`}</p>
    <h2>{`Next Steps`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/step-cli/basic-crypto-operations"
        }}>{`Basic Crypto Operations`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      